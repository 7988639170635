import { vars } from '@iheartradio/web.accomplice';
import {
  addToast,
  AuthenticateCTANotification,
} from '@iheartradio/web.accomplice/toast';
import { isUndefined } from '@iheartradio/web.utilities';
import {
  type FormProps,
  useFetcher,
  useLocation,
  useNavigate,
} from '@remix-run/react';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { $path } from 'remix-routes';

import { useFollowUnfollowEvent } from '~app/analytics/follow-unfollow';
import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import { isStationFollowed } from '~app/api/is-station-followed';
import { useUser } from '~app/contexts/user';
import { getUseFollowUnfollowConfig } from '~app/hooks/use-follow-unfollow/get-use-follow-unfollow-config';
import type { ArtistFollowersAction } from '~app/routes/api.v1.artist.$artistId.followers';
import type { CollectionFollowersAction } from '~app/routes/api.v1.collection.$userCollectionId.followers';
import type { LiveFollowersAction } from '~app/routes/api.v1.live.$stationId.followers';
import type { PodcastFollowersAction } from '~app/routes/api.v1.podcast.$podcastId.followers';
import {
  type FollowUnfollowContentTypes,
  AnalyticsContext,
  AnalyticsSaveTypes,
  LibraryActions,
  Routes,
} from '~app/utilities/constants';

import { useGetPageName } from '../use-get-page-name';

export type UseFollowUnfollowProps = {
  authorId?: string;
  contentId: string;
  contentName?: string;
  contentType: `${FollowUnfollowContentTypes}`;
  context?: string;
  followed?: boolean;
  section?: string;
  onSuccess?: () => void;
};

export const useFollowUnfollow = <
  T extends
    | ArtistFollowersAction
    | CollectionFollowersAction
    | LiveFollowersAction
    | PodcastFollowersAction,
>(
  props: UseFollowUnfollowProps,
) => {
  const {
    authorId,
    contentId,
    contentType,
    contentName,
    context = AnalyticsContext.Overflow,
    followed,
    section,
    onSuccess,
  } = props;
  const fetcher = useFetcher<T>();
  const { onAnalyticsRegGateOpen, onAnalyticsRegGateExit } = useRegGateEvent();
  const user = useUser();
  const navigate = useNavigate();

  const location = useLocation();
  const [isFollowing, setIsFollowing] = useState(followed);
  const { onFollowUnfollow } = useFollowUnfollowEvent();
  const pageName = useGetPageName();

  // The check for `loading` is intentional, rather than checking `idle`.
  // Within Library, many cases occur where the element is fully removed from the DOM, which erases the instance of this hook,
  // which causes the fetcher to never reach an `idle` state after running. So we check `loading` to ensure that it is finished
  // running and successfully returns data.
  const isActionReload = fetcher.state === 'loading' && fetcher.data != null;

  // Retrieve content type specific values to make this hook functional
  const { formAction, remixRoute, regGateTrigger } = useMemo(
    () =>
      getUseFollowUnfollowConfig({
        contentId,
        contentType,
        authorId,
      }),
    [authorId, contentId, contentType],
  );

  useEffect(() => {
    // If not provided a `followed` prop value...
    if (isUndefined(followed)) {
      // Fetch followed state for the station from AMP and save to `isFollowing` state
      isStationFollowed({
        contentId,
        type: contentType,
        userId: authorId,
      })
        .then(isFollowing => setIsFollowing(isFollowing?.followed))
        .catch(() => setIsFollowing(false));
    } else {
      // ...Else set state to the `followed` value from props
      setIsFollowing(followed);
    }
  }, [contentId, followed]);

  // Function to trigger the correct analytics call for each content-type
  function followUnfollowEvent(
    type: AnalyticsSaveTypes.Follow | AnalyticsSaveTypes.Unfollow,
  ) {
    onFollowUnfollow({
      pageName,
      section,
      context,
      assets: {
        asset: {
          id: `${contentType}|${contentId}`,
          name: contentName ?? '',
        },
      },
      type,
    });
  }

  useEffect(() => {
    // If action ran and received data...
    if (isActionReload && fetcher?.data?.ok) {
      onSuccess?.();
      // The station was followed so pop the follow success toast notification
      if (fetcher?.data?.followed) {
        followUnfollowEvent(AnalyticsSaveTypes.Follow);
        addToast({
          kind: 'success',
          text: 'Added to Library',
          actions:
            location?.pathname !== remixRoute ?
              [
                {
                  kind: 'tertiary',
                  color: 'gray',
                  content: 'Go to Library',
                  textColor: vars.color.gray600,
                  size: { xsmall: 'small', medium: 'large' },
                  onPress: () => {
                    navigate(remixRoute);
                  },
                },
              ]
            : [],
        });
      } else {
        // The station was unfollowed so pop the unfollow success toast notification
        followUnfollowEvent(AnalyticsSaveTypes.Unfollow);
        addToast({
          kind: 'success',
          text: 'Removed from Library',
        });
      }
    }
  }, [isActionReload]);

  useEffect(() => {
    // If action failed and/or an `errorMessage` was returned - Pop error toast notification
    if (!fetcher?.data?.ok && fetcher?.data?.errorMessage) {
      setIsFollowing(prev => !prev);
      addToast({
        kind: 'error',
        text: fetcher?.data?.errorMessage,
      });
    }
  }, [fetcher?.data]);

  return {
    fetcher,
    isFollowing,
    formAction,
    FollowForm: useMemo(
      () =>
        forwardRef<HTMLFormElement, FormProps>(function FollowForm(
          { children, ...restProps }: FormProps,
          ref,
        ) {
          return (
            <fetcher.Form
              {...restProps}
              action={formAction}
              method="POST"
              onSubmit={event => {
                event.stopPropagation();
                onSuccess?.();
                if (user?.isAnonymous) {
                  event.preventDefault();
                  addToast(
                    AuthenticateCTANotification({
                      trigger: regGateTrigger,
                      text: 'Log in to save your favorites and access Your Library',
                      size: { xsmall: 'small', medium: 'large' },
                      paths: [
                        $path(Routes.Login, { action: 'auth' }),
                        $path(Routes.SignUp),
                      ],
                      navigate,
                      onAnalyticsRegGateOpen,
                      onAnalyticsRegGateExit,
                    }),
                  );
                } else {
                  setIsFollowing(true);
                }
              }}
              ref={ref}
            >
              {children}
              <input name="type" type="hidden" value={LibraryActions.Follow} />
            </fetcher.Form>
          );
        }),
      [fetcher, formAction, onSuccess, regGateTrigger, user?.isAnonymous],
    ),

    follow: () => {
      if (user?.isAnonymous) {
        addToast(
          AuthenticateCTANotification({
            trigger: regGateTrigger,
            text: 'Log in to save your favorites and access Your Library',
            size: { xsmall: 'small', medium: 'large' },
            paths: [
              $path(Routes.Login, { action: 'auth' }),
              $path(Routes.SignUp),
            ],
            navigate,
            onAnalyticsRegGateOpen,
            onAnalyticsRegGateExit,
          }),
        );
      } else {
        setIsFollowing(true);
        return fetcher.submit(
          { type: LibraryActions.Follow },
          {
            action: formAction,
            method: 'POST',
          },
        );
      }
    },

    UnfollowForm: useMemo(
      () =>
        forwardRef<HTMLFormElement, FormProps>(function UnfollowForm(
          { children, ...restProps }: FormProps,
          ref,
        ) {
          return (
            <fetcher.Form
              {...restProps}
              action={formAction}
              method="POST"
              onSubmit={event => {
                event.stopPropagation();
                onSuccess?.();
                if (user?.isAnonymous) {
                  event.preventDefault();
                  addToast(
                    AuthenticateCTANotification({
                      trigger: regGateTrigger,
                      text: 'Log in to save your favorites and access Your Library',
                      size: { xsmall: 'small', medium: 'large' },
                      paths: [
                        $path(Routes.Login, { action: 'auth' }),
                        $path(Routes.SignUp),
                      ],
                      navigate,
                      onAnalyticsRegGateOpen,
                      onAnalyticsRegGateExit,
                    }),
                  );
                } else {
                  setIsFollowing(false);
                }
              }}
              ref={ref}
            >
              {children}
              <input
                name="type"
                type="hidden"
                value={LibraryActions.Unfollow}
              />
            </fetcher.Form>
          );
        }),
      [fetcher, formAction, onSuccess, regGateTrigger, user?.isAnonymous],
    ),

    unfollow: () => {
      if (user?.isAnonymous) {
        addToast(
          AuthenticateCTANotification({
            trigger: regGateTrigger,
            text: 'Log in to save your favorites and access Your Library',
            size: { xsmall: 'small', medium: 'large' },
            paths: [
              $path(Routes.Login, { action: 'auth' }),
              $path(Routes.SignUp),
            ],
            navigate,
            onAnalyticsRegGateOpen,
            onAnalyticsRegGateExit,
          }),
        );
      } else {
        setIsFollowing(false);
        return fetcher.submit(
          { type: LibraryActions.Unfollow },
          { action: formAction, method: 'POST' },
        );
      }
    },
  };
};
